import React, { useContext } from "react"
import { Box } from "@chakra-ui/react"

import { UserContext } from "~config/user-context"
import NavBar from "~components/nav-bar"
import AuthPage from "~pages/auth"
import FullscreenLoading from "~components/shared/fullscreen-loading"
import ContactFooter from "~components/shared/contact-footer"

type LayoutProps = {
    children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const { user, isLoading } = useContext(UserContext)

    if (isLoading) return <FullscreenLoading />

    if (user)
        return (
            <>
                <NavBar />
                <Box minH={"100vh"}>{children}</Box>
                <ContactFooter />
            </>
        )

    return <AuthPage />
}

export default Layout
