export enum Roles {
	ADMIN = "admin",
	PLAYER = "player",
	OWNER = "owner",
	OTHER = "other",
}

export enum Collections {
	USERS = "Users",
	ROOMS = "Rooms",
	NOTIFICATIONS = "Notifications",
}
