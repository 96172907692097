import React, { useEffect, useState } from "react"
import firebase from "gatsby-plugin-firebase"

import { User } from "~models"
import { subscribeToNotificationCount } from "~queries"

export const UserContext = React.createContext<UserProviderType>({
    user: undefined,
    isLoading: true,
    userId: "",
    unreadNotifs: 0,
})

type UserProviderType = {
    user: User | undefined
    isLoading: boolean
    userId: string
    unreadNotifs: number
}

export const UserProvider = ({ children }: { children: React.ReactNode }) => (
    <UserContext.Provider value={{ ...useUser() }}>
        {children}
    </UserContext.Provider>
)

function useUser(): UserProviderType {
    const [{ user, isLoading }, setUser] = useState<{
        user: User | undefined
        isLoading: boolean
    }>({
        user: undefined,
        isLoading: true,
    })
    const [userId, setUserId] = useState("")
    const [unreadNotifs, setUnreadNotifs] = useState<number>(0)
    const [firebaseReady, setFirebaseReady] = useState(false)

    useEffect(() => {
        if (!firebase) return
        setFirebaseReady(true)
    }, [firebase])

    useEffect(() => {
        if (user)
            subscribeToNotificationCount(user.id, snapshot =>
                setUnreadNotifs(parseInt(snapshot.data()?.notViewed))
            )
    }, [user])

    useEffect(() => {
        if (!firebaseReady) return
        firebase.auth().onAuthStateChanged(userAuth => {
            if (userAuth) setUserId(userAuth.uid)
            else {
                setUser({
                    user: undefined,
                    isLoading: false,
                })
            }
        })
    }, [firebaseReady])

    useEffect(() => {
        if (firebase.auth().currentUser && firebase.auth().currentUser!.uid) {
            setUserId(firebase.auth().currentUser!.uid)
        }
    }, [])

    useEffect(() => {
        if (!userId || !firebaseReady) return
        firebase
            .firestore()
            .collection("Users")
            .doc(userId)
            .onSnapshot(doc => {
                setUser({ user: doc.data() as User, isLoading: false })
            })
    }, [userId, firebaseReady])

    return {
        user,
        isLoading,
        userId,
        unreadNotifs,
    }
}
