import { extendTheme, ThemeConfig } from "@chakra-ui/react"

const fonts = {
    // body: "Poppins",
    // heading: "Montserrat",
}

const fontSizes = {
    "2xs": "12px",
    xs: "14px",
    sm: "16px",
    md: "22px",
    lg: "27px",
    xl: "44px",
    "2xl": "48px",
    "3xl": "62px",
    "4xl": "80px",
}

const colors = {
    brand: {
        default: "#f74734",
        50: "#fddcd8",
        100: "#fcb8b1",
        200: "#fa9589",
        300: "#f87162",
        400: "#f74734",
        500: "#eb200a",
        600: "#c41b08",
        700: "#9d1607",
        800: "#761005",
        900: "#3b0802",
    },
    gradientBg: "linear-gradient(145deg, #cacaca, #f0f0f0)",
    white: "#ffffff",
}

const borders = {
    sm: "1px solid rgba(170,170,170,0.4)",
}

const shadows = {
    "neu-xl": "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
    "neu-md": "12px 12px 25px rgba(0,0,0,0.25)",
    "neu-sm": "5px 5px 7px 0 rgba(0,0,0,0.25)",
    "neu-xs": "2px 2px 5px rgba(0,0,0,0.25)",
    "neu-xs-inset": "2px 2px 5px rgba(0,0,0,0.25) inset",
}

const breakpoints = ["30em", "48em", "62em", "80em", "92em"]

const components = {
    Input: {
        variants: {
            flushed: {
                bg: "transparent",
            },
        },
        defaultProps: {
            size: "sm",
            variant: "flushed",
            focusBorderColor: "black",
        },
    },
    Textarea: {
        variants: {
            flushed: {
                bg: "transparent",
            },
        },
        defaultProps: {
            size: "sm",
            variant: "flushed",
            focusBorderColor: "black",
        },
    },
    Text: {
        baseStyle: {
            color: "primary",
        },
    },
    Heading: {
        baseStyle: {
            color: "primary",
        },
        sizes: {
            xl: {
                fontSize: "xl",
            },
        },
        defaultProps: {
            size: "xl",
        },
    },
    Button: {
        baseStyle: {
            fontWeight: "600",
            bg: "rgba(170,170,170,0.15)",
        },
        variants: {
            default: {
                boxShadow: "neu-xs",
                _hover: {
                    boxShadow: "neu-sm",
                },
                _active: {
                    boxShadow: "neu-xs-inset",
                },
            },
            brand: {
                bg: "gray.100",
                _hover: {
                    bg: "brand.400",
                    textColor: "white",
                },
                _active: {
                    bg: "brand.600",
                    textColor: "white",
                },
            },
        },
        sizes: {
            md: {
                fontSize: "sm",
            },
        },
        defaultProps: {
            variant: "default",
        },
    },
}

const config: ThemeConfig = {
    initialColorMode: "dark",
    useSystemColorMode: true,
}

export const theme = extendTheme({
    fonts,
    fontSizes,
    colors,
    breakpoints,
    components,
    config,
    borders,
    shadows,
})
