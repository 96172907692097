import React, { useState, useRef } from "react"
import firebase from "gatsby-plugin-firebase"
import { navigate } from "gatsby"
import {
    Box,
    Flex,
    Button,
    useColorMode,
    Drawer,
    DrawerBody,
    Heading,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from "@chakra-ui/react"
import {
    FaGripLines,
    FaDoorOpen,
    FaListAlt,
    FaUser,
    FaSun,
    FaMoon,
} from "react-icons/fa"

import ResponsiveBlock from "~components/shared/responsive-block"

const NavBar = () => {
    const { colorMode, toggleColorMode } = useColorMode()

    const [isOpen, setIsOpen] = useState(false)
    const btnRef = useRef(null)

    return (
        <>
            <Box
                bg="primary"
                // position="fixed"
                w="100%"
                top={0}
                left={0}
                h="72px"
                boxShadow={"sm"}
            >
                <ResponsiveBlock
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Heading size="sm">Valoqueue</Heading>
                    <Button
                        onClick={() => setIsOpen(true)}
                        display={["initial", "none"]}
                    >
                        <FaGripLines />
                    </Button>
                    <Flex
                        alignItems="center"
                        gridGap={4}
                        display={["none", "flex"]}
                    >
                        <Button
                            onClick={() => navigate("/your-room")}
                            gridGap={2}
                        >
                            Your Room <FaDoorOpen />
                        </Button>
                        <Button onClick={() => navigate("/")} gridGap={2}>
                            Queues
                            <FaListAlt />
                        </Button>
                        <Button
                            onClick={() => navigate("/account")}
                            gridGap={2}
                        >
                            Account <FaUser />
                        </Button>
                        <Button onClick={() => toggleColorMode()} gridGap={2}>
                            {colorMode === "dark" ? (
                                <>
                                    Light <FaSun />
                                </>
                            ) : (
                                <>
                                    Dark <FaMoon />
                                </>
                            )}
                        </Button>
                        <Button
                            onClick={() => firebase.auth().signOut()}
                            gridGap={2}
                        >
                            Logout
                        </Button>
                    </Flex>
                </ResponsiveBlock>
            </Box>
            <Box h="72px" />
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={() => setIsOpen(false)}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Valoqueue</DrawerHeader>
                    <DrawerBody>
                        <Flex flexDirection="column" gridGap={4}>
                            <Button
                                onClick={() => {
                                    navigate("/your-room")
                                    setIsOpen(false)
                                }}
                            >
                                Your Room
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate("/")
                                    setIsOpen(false)
                                }}
                            >
                                Queues
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate("/account")
                                    setIsOpen(false)
                                }}
                            >
                                Account
                            </Button>
                            <Button
                                onClick={() => {
                                    toggleColorMode()
                                    setIsOpen(false)
                                }}
                            >
                                Light/Dark
                            </Button>
                            <Button
                                onClick={() => {
                                    firebase.auth().signOut()
                                    setIsOpen(false)
                                }}
                            >
                                Logout
                            </Button>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default NavBar
