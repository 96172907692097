import React from "react"
import { Center, Flex, Heading, Spinner } from "@chakra-ui/react"

const FullscreenLoading = () => (
    <Center
        w="100vw"
        h="100vh"
        backgroundImage="url(https://m.media-amazon.com/images/G/01/sm/amped/valorant_s3/desktop_hero_2x._CB657709119_.jpg)"
    >
        <Flex direction="column" align="center">
            <Heading mb={30}>Valoqueue</Heading>
            <Spinner size={"xl"} />
        </Flex>
    </Center>
)

export default FullscreenLoading
