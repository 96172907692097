import React, { useState } from "react"
import firebase from "gatsby-plugin-firebase"
import {
    Box,
    Input,
    FormLabel,
    Heading,
    Divider,
    FormControl,
    Button,
    useToast,
    Text,
} from "@chakra-ui/react"

import ResponsiveBlock from "~components/shared/responsive-block"
import { createNewUser } from "~queries"

const AuthPage = () => {
    const toast = useToast()

    const [isSignUp, setIsSignUp] = useState(false)

    const [username, setUsername] = useState("")
    const [teamName, setTeamName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const createAccount = async () => {
        try {
            const {
                user,
            } = await firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)

            await createNewUser({
                id: user!.uid,
                username: username,
                email,
                team: teamName,
            })
            toast({
                title: "Account created.",
                description: "We've created your account for you.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        } catch (e) {
            toast({
                title: "Account creation failed.",
                description: e.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        }
    }

    const signIn = async () => {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password)

            toast({
                title: "Successful sign in.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        } catch (e) {
            toast({
                title: "Sign in failed.",
                description: e.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        }
    }

    return (
        <ResponsiveBlock
            display="flex"
            h="100vh"
            justifyContent="center"
            backgroundImage="url(https://m.media-amazon.com/images/G/01/sm/amped/valorant_s3/desktop_hero_2x._CB657709119_.jpg)"
        >
            <Box
                display="flex"
                flexDirection="column"
                marginTop="96px"
                color="white"
            >
                <Heading color="primary">Welcome to VallyQ</Heading>

                <Divider my={10} />
                <Box display="flex">
                    <Text textAlign="center" fontSize="22px" mr="10px">
                        {isSignUp ? "Sign Up" : "Log In"}
                    </Text>
                    <Button bg="teal" onClick={() => setIsSignUp(!isSignUp)}>
                        Switch to {!isSignUp ? "Sign Up" : "Log In"}
                    </Button>
                </Box>
                <FormControl>
                    {isSignUp && (
                        <>
                            <FormLabel>Username</FormLabel>
                            <Input
                                bg="rgba(0,0,0,0.4)"
                                type="text"
                                value={username}
                                onChange={event =>
                                    setUsername(event.target.value)
                                }
                            />
                            <FormLabel>Team Name</FormLabel>
                            <Input
                                bg="rgba(0,0,0,0.4)"
                                type="text"
                                value={teamName}
                                onChange={event =>
                                    setTeamName(event.target.value)
                                }
                            />
                        </>
                    )}
                    <FormLabel>Email</FormLabel>
                    <Input
                        bg="rgba(0,0,0,0.4)"
                        type="email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                    />
                    <Box h="20px" />
                    <FormLabel>Password</FormLabel>
                    <Input
                        bg="rgba(0,0,0,0.4)"
                        type="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    />
                </FormControl>
                <Box h="20px" />
                <Button bg="teal" onClick={isSignUp ? createAccount : signIn}>
                    Submit
                </Button>
                <Box h="20px" />
                <Button bg="grey" onClick={() => alert("Iykyk ;)")}>
                    What is this?
                </Button>
            </Box>
        </ResponsiveBlock>
    )
}

export default AuthPage
