import React from "react"
import { Box, Flex, Link, Image, Heading, Text } from "@chakra-ui/react"
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"

import ResponsiveBlock from "~components/shared/responsive-block"
import breachWhite from "~images/Breach\_White\_Full.svg"

const Footer = () => {
    return (
        <Box>
            <Box bg="black" py={[20, 12]}>
                <ResponsiveBlock wide>
                    <Flex
                        w="100%"
                        h="100%"
                        direction={{ base: "column", md: "row" }}
                    >
                        <Flex
                            w={{ base: "100%", md: "60%" }}
                            h="100%"
                            pl={{ base: 4, md: "10%" }}
                            justify="center"
                            direction="column"
                        >
                            <Heading size="sm">Built by</Heading>
                            <Flex
                                justify={{ base: "center", md: "flex-start" }}
                            >
                                <Image
                                    w={{ base: "156px", md: "232px" }}
                                    mb={6}
                                    src={breachWhite}
                                />
                            </Flex>
                            <Flex
                                h={{ base: "100%", md: "60%" }}
                                direction="column"
                                justify="space-around"
                            >
                                <Text
                                    color="white"
                                    fontSize={{ base: "2xs", md: "xs" }}
                                >
                                    8000 Innovation Park Dr.
                                </Text>
                                <Text
                                    color="white"
                                    fontSize={{ base: "2xs", md: "xs" }}
                                >
                                    Baton Rouge, LA, 70820
                                </Text>

                                <Text
                                    color="white"
                                    fontSize={{ base: "2xs", md: "xs" }}
                                >
                                    Inquiries: (225) 610-0701
                                </Text>
                                <Text
                                    color="white"
                                    fontSize={{ base: "2xs", md: "xs" }}
                                >
                                    Support: (337) 304-4448
                                </Text>
                                <Text
                                    color="white"
                                    fontSize={{ base: "2xs", md: "xs" }}
                                >
                                    contact@breachsoftware.com
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex
                            h="100%"
                            w={{ base: "100%", md: "50%" }}
                            align="center"
                        ></Flex>
                    </Flex>
                </ResponsiveBlock>
            </Box>
            <Box pt={12} pb={16} bg="black" borderTop="1px solid grey">
                <ResponsiveBlock wide>
                    <Flex
                        px="10%"
                        w={{ base: "100%", md: "35%" }}
                        justify="space-between"
                    >
                        <Link
                            isExternal
                            href="https://www.facebook.com/breachsoftware"
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 1, y: 1 }}
                        >
                            <FaFacebook size={40} />
                        </Link>
                        <Link
                            isExternal
                            href="https://www.instagram.com/breachsoftware/"
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 1, y: 1 }}
                        >
                            <FaInstagram size={40} />
                        </Link>
                        <Link
                            isExternal
                            href="https://www.linkedin.com/company/breach-software"
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 1, y: 1 }}
                        >
                            <FaLinkedin size={40} />
                        </Link>
                        <Link
                            isExternal
                            href="https://twitter.com/breachsoftware"
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 1, y: 1 }}
                        >
                            <FaTwitter size={40} />
                        </Link>
                    </Flex>
                </ResponsiveBlock>
            </Box>
        </Box>
    )
}

export default Footer
