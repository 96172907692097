export * from "~models/room"
export * from "~models/user"
export * from "~models/team"
export * from "~models/notification"
export * from "~models/enums"

export type DBResult<T> = {
	status: "success" | "error"
	message: string
	data?: T
}
