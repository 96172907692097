import firebase from "gatsby-plugin-firebase"

import { User, InsertUser, UpdateUser, DBResult, Collections } from "~models"

export async function createNewUser(
	user: InsertUser
): Promise<DBResult<User[]>> {
	try {
		const dbUser: User = { ...user, role: "admin", currentRoom: "" }
		await firebase
			.firestore()
			.collection(Collections.USERS)
			.doc(user.id)
			.set(dbUser)
		return {
			status: "success",
			message: "Successfully created new user.",
		}
	} catch (e) {
		console.error(e)
		return {
			status: "error",
			message: "Failed to create new user.",
		}
	}
}

export async function updateUser(
	userId: string,
	user: UpdateUser
): Promise<DBResult<User[]>> {
	const dbUser: UpdateUser = { ...user }
	const userRef = firebase
		.firestore()
		.collection(Collections.USERS)
		.doc(userId)

	try {
		await userRef.update(dbUser)
		return {
			status: "success",
			message: "Successfully updated user.",
		}
	} catch (err) {
		console.error(err)
		return {
			status: "error",
			message: "Failed to update user.",
		}
	}
}

export async function getUserByEmail(email: string): Promise<DBResult<User>> {
	const userRef = firebase.firestore().collection(Collections.USERS)

	try {
		const userQuery = await userRef.where("email", "==", email).get()
		if (userQuery.empty)
			return {
				status: "error",
				message: "No user with provided email.",
			}

		const ret: User = userQuery.docs[0].data() as User
		return {
			status: "success",
			message: "Successfully updated user.",
			data: ret,
		}
	} catch (err) {
		console.error(err)
		return {
			status: "error",
			message: "Failed to get user by email.",
		}
	}
}

export async function getUsersByIds(ids: string[]): Promise<DBResult<User[]>> {
	if (ids.length > 10)
		return {
			status: "error",
			message: "Must supply fewer than 10 ids.",
			data: [],
		}

	if (ids.length < 1)
		return {
			status: "error",
			message: "Must supply at least one id.",
			data: [],
		}

	try {
		const data = await firebase
			.firestore()
			.collection(Collections.USERS)
			.where("id", "in", ids)
			.get()

		const users: User[] = []

		data.forEach((datum) => users.push(datum.data() as User))

		return {
			status: "success",
			message: "Successfully retrieved users.",
			data: users,
		}
	} catch (e) {
		return {
			status: "error",
			message: "Failed to get users by ids.",
			data: [],
		}
	}
}
