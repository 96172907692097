import firebase from "gatsby-plugin-firebase"
import { useEffect, useState } from "react"

import { Room, InsertRoom, UpdateRoom, DBResult, Collections } from "~models"

export async function addUserToRoom(
    userId: string,
    roomId: string
): Promise<DBResult<undefined>> {
    try {
        return firebase.firestore().runTransaction(async transaction => {
            const roomRef = firebase
                .firestore()
                .collection(Collections.ROOMS)
                .doc(roomId)

            const room: Room = (await transaction.get(roomRef)).data() as Room

            if (!room.participants.includes(userId)) {
                // Remove the user from their other rooms.
                const userRoomRef = await firebase
                    .firestore()
                    .collection(Collections.ROOMS)
                    .where("participants", "array-contains", userId)
                    .get()

                if (userRoomRef.docs.length > 0) {
                    // Deletes room if it's now empty.
                    if (userRoomRef.docs[0].data().participants.length <= 1) {
                        transaction.delete(userRoomRef.docs[0].ref)
                    } else {
                        transaction.update(userRoomRef.docs[0].ref, {
                            participants: firebase.firestore.FieldValue.arrayRemove(
                                userId
                            ),
                        })
                    }
                }

                const roomUpdateData: UpdateRoom = {
                    participants: [...room.participants, userId],
                    numParticipants: room.numParticipants + 1,
                }
                transaction.update(roomRef, roomUpdateData)
                return {
                    status: "success",
                    message: `Successfully added user to room '${roomId}'`,
                }
            }
            return {
                status: "error",
                message: `User is already in room '${roomId}'`,
            }
        })
    } catch (e) {
        console.error(e)
        return {
            status: "error",
            message: `Failed to add user to room '${roomId}': ${e.message}`,
        }
    }
}

export async function removeUserFromRoom(
    userId: string,
    roomId: string
): Promise<DBResult<undefined>> {
    try {
        return firebase.firestore().runTransaction(async transaction => {
            const roomRef = firebase
                .firestore()
                .collection(Collections.ROOMS)
                .doc(roomId)

            const room: Room = (await transaction.get(roomRef)).data() as Room

            if (room.participants.includes(userId)) {
                if (
                    room.participants.filter(
                        participant => participant !== userId
                    ).length === 0
                ) {
                    transaction.delete(roomRef)
                    return {
                        status: "success",
                        message: `Successfully removed user from room '${roomId}'`,
                    }
                }

                const newParticipants = room.participants.filter(
                    participant => participant !== userId
                )

                transaction.update(roomRef, {
                    participants: newParticipants,
                    numParticipants: newParticipants.length,
                    ownerId: newParticipants[0],
                })
                return {
                    status: "success",
                    message: `Successfully removed user from room '${roomId}'`,
                }
            }
            return {
                status: "error",
                message: `User does not exist in room '${roomId}'`,
            }
        })
    } catch (e) {
        console.error(e)
        return {
            status: "error",
            message: `Failed to remove from room '${roomId}: ${e.message}`,
        }
    }
}

export async function RemoveUserFromAllRooms(userId: string) {
    try {
        const roomsQuery = await firebase
            .firestore()
            .collection(Collections.ROOMS)
            .where("participants", "array-contains", userId)
            .get()

        roomsQuery.forEach(room => {
            removeUserFromRoom(userId, room.data().id)
        })
        return {
            status: "success",
            message: `Successfully removed user from all rooms.`,
        }
    } catch (e) {
        console.error(e)
        return {
            status: "error",
            message: `Failed to remove user from all rooms: ${e.message}`,
        }
    }
}

export async function createRoom(room: InsertRoom): Promise<DBResult<Room>> {
    if (!room.id) {
        return {
            status: "error",
            message: `Cannot create a room without an id.`,
        }
    }
    const dbRoom: Room = {
        ...room,
        inGame: false,
        discordLink: room.discordLink || "",
        maxParticipants: room.maxParticipants || 10,
        description: room.description || "",
        numParticipants: 0,
        invitees: [],
        redTeam: [],
        blueTeam: [],
        createdAt: Date.now(),
    }
    try {
        await RemoveUserFromAllRooms(room.ownerId)

        return await firebase.firestore().runTransaction(async transaction => {
            const roomRef = firebase
                .firestore()
                .collection(Collections.ROOMS)
                .doc(dbRoom.id)

            if ((await transaction.get(roomRef)).exists)
                return {
                    status: "error",
                    message: `Room with id '${dbRoom.id}' already exists.`,
                }

            transaction.set(roomRef, dbRoom)
            return {
                status: "success",
                message: `Successfully created room '${dbRoom.id}'`,
                data: dbRoom,
            }
        })
    } catch (e) {
        return {
            status: "error",
            message: `Failed to create room '${dbRoom.id}'`,
        }
    }
}

export async function updateRoom(
    roomId: string,
    roomUpdateData: UpdateRoom
): Promise<DBResult<undefined>> {
    try {
        await firebase
            .firestore()
            .collection(Collections.ROOMS)
            .doc(roomId)
            .update(roomUpdateData)
        return {
            status: "success",
            message: `Successfully updated room '${roomId}'`,
        }
    } catch (e) {
        console.error(`Error updating room: ${e}`)
        return {
            status: "error",
            message: `Failed to update room '${roomId}'`,
        }
    }
}

export async function deleteRoom(roomId: string): Promise<DBResult<undefined>> {
    try {
        await firebase
            .firestore()
            .collection(Collections.ROOMS)
            .doc(roomId)
            .delete()
        return {
            status: "success",
            message: `Successfully deleted room '${roomId}'`,
        }
    } catch (e) {
        return {
            status: "error",
            message: `Failed to delete room '${roomId}'`,
        }
    }
}

export async function getAllPublicRooms(): Promise<DBResult<Room[]>> {
    try {
        const data = await firebase
            .firestore()
            .collection(Collections.ROOMS)
            .limit(10)
            .where("private", "==", false)
            .get()

        const res: Room[] = []
        data.forEach(datum => res.push(datum.data() as Room))

        return {
            status: "success",
            message: "Successfully retrieved all rooms.",
            data: res,
        }
    } catch (e) {
        return {
            status: "error",
            message: `Failed to get all rooms.`,
        }
    }
}

export function subscribeToPublicRooms(callback: (snapshot: any) => void) {
    return firebase
        .firestore()
        .collection(Collections.ROOMS)
        .where("private", "==", false)
        .onSnapshot(callback)
}

export function useUserRoomListener(
    userId: string
): { room: Room | undefined; isLoading: boolean } {
    const [room, setRoom] = useState<Room | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        firebase
            .firestore()
            .collection(Collections.ROOMS)
            .where("participants", "array-contains", userId)
            .onSnapshot(snapshot => {
                if (isLoading) setIsLoading(false)
                if (snapshot.docs.length > 0) {
                    setRoom(snapshot.docs[0].data() as Room)
                } else {
                    setRoom(undefined)
                }
            })
    }, [])

    return { room, isLoading }
}
