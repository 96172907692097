import firebase from "gatsby-plugin-firebase"

import {
	Notification,
	InsertNotification,
	UpdateNotification,
	Collections,
	DBResult,
} from "~models"

export async function createNewNotification(
	userId: string,
	notification: InsertNotification
): Promise<DBResult<Notification[]>> {
	try {
		return firebase.firestore().runTransaction(async (trx) => {
			const notifsCollectionRef = firebase
			.firestore()
			.collection(Collections.USERS)
			.doc(userId)
			.collection(Collections.NOTIFICATIONS)

		const countDoc = await trx.get(notifsCollectionRef
			.doc("count"))
		
			if(countDoc.exists) {
				trx.update(notifsCollectionRef.doc("count"), { notViewed: firebase.firestore.FieldValue.increment(1) })
			}
			else {
				trx.set(notifsCollectionRef.doc("count"), { notViewed: 1 })
			}

		const notifRef = notifsCollectionRef.doc()

		const dbNotif: Notification = {
			...notification,
			id: notifRef.id,
			createdAt: Date.now(),
		}

		trx.set(notifRef, dbNotif)
	
		return {
			status: "success",
			message: "Successfully added notification.",
		}
		})
		
	} catch (e) {
		console.error(e)
		return {
			status: "error",
			message: `Failed to add notification: ${e}`,
		}
	}
}

export async function setAllViewed(
	userId: string
): Promise<DBResult<Notification[]>> {
	try {
		const notifRef = firebase
			.firestore()
			.collection(Collections.USERS)
			.doc(userId)
			.collection(Collections.NOTIFICATIONS)

		await notifRef.doc("count").update({ notViewed: 0 })

		return {
			status: "success",
			message: "Successfully set unviewed to 0.",
		}
	} catch (e) {
		console.error(e)
		return {
			status: "error",
			message: "Failed to set all notifs as viewed.",
		}
	}
}

export async function getNotificationsByUser(
	userId: string
): Promise<DBResult<Notification[]>> {
	try {
		const data = await firebase
			.firestore()
			.collection(Collections.USERS)
			.doc(userId)
			.collection(Collections.NOTIFICATIONS)
			.orderBy("createdAt")
			.get()

		const notifs: Notification[] = []

		data.forEach((datum) => notifs.push(datum.data() as Notification))
		return {
			status: "success",
			message: "Successfully retrieved notifications.",
			data: notifs,
		}
	} catch (e) {
		return {
			status: "error",
			message: "Failed to get notifications.",
		}
	}
}

export function subscribeToNotifications(
	userId: string,
	callback: (snapshot: firebase.firestore.QuerySnapshot) => void
) {
	const subscriber = firebase
		.firestore()
		.collection(Collections.USERS)
		.doc(userId)
		.collection(Collections.NOTIFICATIONS).orderBy("createdAt", 'desc')

		return subscriber.onSnapshot(callback)

		 
}

export function subscribeToNotificationCount(
	userId: string,
	callback: (snapshot: firebase.firestore.DocumentSnapshot) => void
) {
	return firebase
		.firestore()
		.collection(Collections.USERS)
		.doc(userId)
		.collection(Collections.NOTIFICATIONS)
		.doc("count")
		.onSnapshot(callback)
}
